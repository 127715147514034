<template>
    <div v-loading.fullscreen.lock="loading">
        <router-team-search-bar-component
            AddAuth="新增权限分组"
            @search="get_route_team_index"
            @addTeam="addTeam"
        ></router-team-search-bar-component>
        <common-table-component
            details_auth="权限分组详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_route_team"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_route_team_index"
        ></common-page-component>
        <router-team-edit-component
            edit_auth="修改权限分组"
            del_auth="删除权限分组"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :route_team_details_data="froute_team_details_data"
            @exitRouteTeamDialog="dialogExit"
            @search="get_route_team_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_route_team"
        ></router-team-edit-component>
    </div>
</template>

<script>
import { RouterTeamIndexRequest,RouterTeamDetailsRequest } from '@/network/RouterTeam.js'

import RouterTeamSearchBarComponent from '@/components/RouterTeam/RouterTeamSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import RouterTeamEditComponent from '@/components/RouterTeam/RouterTeamEditComponent'

export default {
    name: 'RouteTeamView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'icon',
                    label: '图标',
                    width: '70px',
                    html: (row) => {
                        return '<img src="'+this.$store.state.baseURL+'icon/'+row.icon+'.png" height="30px"></img>'
                    }
                },
                {
                    prop: 'name',
                    label: '分组名称',
                    minWidth: '100px'
                },
                {
                    prop: 'create_staff_name',
                    label: '最后修改人',
                    minWidth: '200px'
                },
                {
                    prop: 'create_time',
                    label: '最后修改时间',
                    minWidth: '160px'
                }
            ],
            froute_team_details_data: {}
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_route_team_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            RouterTeamIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_route_team(id) {
            this.fid = id
            this.fdialogFormVisible = true
            RouterTeamDetailsRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.froute_team_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_route_team_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        RouterTeamSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        RouterTeamEditComponent,
    },
    watch: {},
}
</script>

<style lang='less' scoped></style>